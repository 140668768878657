<template>
  <div class="free_week">
    <div class="free_week__filter"></div>

    <div class="padding_top"></div>

    <div>
      <b-container>
        <b-row class="full_height" align-v="end">
          <b-col cols="12">
            <b-row class="page_header" align-h="center">
              <b-col cols="12" md="5">
                <h1 class="page_title__subscription">
                  Подписка <span class="accent">БК</span> Состояние
                </h1>
                <p class="page_subtitle__subscription">
                  Выберите подписку для себя
                </p>

                <h2 class="subscription_easy_justi_text">
                  Множество уровней с отборным контентом и вопросами, проходя
                  которые, вы будете держать себя в фокусе на своих истинных
                  желаниях и целях.
                </h2>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12">
            <div v-swiper:globalSwiper="swiperOption">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-b-modal.global_goal
                  v-for="(sub, index) in subscribes"
                  :key="index"
                >
                  <SubCard :item="sub" :index="index" />
                </div>
              </div>

              <div class="swiper-pagination"></div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SubCard from "../../components/bkonline/SubCard.vue";

export default {
  name: "Subscribe",
  components: {
    SubCard,
  },
  data() {
    return {
      swiperOption: {
        mousewheel: {
          forceToAxis: true,
        },
        clickable: false,
        // keyboard: {
        //   enabled: true,
        //   onlyInViewport: true,
        // },
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 15,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          1024: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
  methods: {
    ...mapActions(["getSubscriptionTypes"]),
  },
  computed: {
    subscribes() {
      return this.$store.state.bkonline.subscriptionTypes;
    },
    subscription() {
      return this.$store.state.user.subscription;
    },
  },
  async mounted() {
    try {
      this.$store.commit("SET_LOADING", true);

      if (localStorage.getItem("token")) {
        await this.$store.dispatch("getUser");

        await this.getSubscriptionTypes();
      }

      this.$store.commit("SET_LOADING", false);
    } catch (error) {
      this.$store.commit("SET_LOADING", false);
    }
  },
};
</script>

<style lang="scss">
.full_height {
  height: 80vh;
  padding-bottom: 4vh;
  margin-bottom: 50px;
}

.free_week {
  position: relative;
  padding-bottom: 30px;

  background-position: 40% 40%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/ali-kazal-1OkIXsRu4Oo-unsplash.jpg");
}

.free_week__filter {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background: linear-gradient(
    0deg,
    rgba(20, 20, 20, 1) 10%,
    rgba(31, 31, 31, 0.4) 100%
  );
}

.page_title__subscription {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.page_subtitle__subscription {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: rgba($color: $light_gray__dark, $alpha: 1);
}
.subscription_easy_justi_text {
  text-align: justify;
  font-size: 15px;
  font-weight: 500;
  color: rgba($color: $white, $alpha: 0.8);
  padding-bottom: 30px;
}

.subscription_card_wrapper {
  margin-bottom: 30px;
}
</style>
